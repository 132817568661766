import firebase from "firebase/app";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import acceptOffer from "astrid-firestore/src/api/offers/acceptOffer";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import declineOffer from "astrid-firestore/src/api/offers/declineOffer";

import Flex from "../../../../../components/Flex/Flex";
import { useProfile } from "../../../../authentication/state/profile";
import Button from "../../../../ui/components/Buttons/Button";
import PrimaryActionButton from "../../../../ui/components/Buttons/PrimaryActionButton";

export default function OfferActions({ offer }) {
	const { t } = useTranslation();

	const user = useProfile();

	const [loading, setLoading] = useState(false);

	const onClickDecline = async () => {
		setLoading(true);
		await declineOffer(firebase, { offer, user });
		setLoading(false);
	};

	const onClickAccept = async () => {
		setLoading(true);
		await acceptOffer(firebase, { offer, user });
		setLoading(false);
	};

	return (
		<>
			{offer.status === offerStatuses.ACCEPTED && (
				<Button
					as={Link}
					floated="right"
					to={`/production/${offer?.production?.id}`}
					content={t("toProduction")}
					color="blue"
				/>
			)}

			{offer.status === offerStatuses.OFFERED && (
				<Flex style={{ gap: 10 }}>
					<PrimaryActionButton
						content={t("offerDecline")}
						loading={loading}
						disabled={loading}
						onClick={onClickDecline}
						negative
						fluid
					/>

					<PrimaryActionButton
						loading={loading}
						disabled={loading}
						content={t("offerAccept")}
						onClick={onClickAccept}
						positive
						fluid
					/>
				</Flex>
			)}
		</>
	);
}
