import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Author } from "astrid-firestore/src/api/authors/types/Author";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateAuthorForm({ onClose, organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Author,
		defaultValues: {
			name: "",
			languages: [],
			publisherIds: [organization.id],
		},
		onSubmit: (data) => {
			return api.authors.createOrUpdate(data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createAuthor", "Create author")}
			error={error}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />

				<Form.LanguageSelect name="languages" label={t("languages", "Languages")} multiple />
			</Form>
		</ModalPrompt>
	);
}
