import DataTable from "../../../ui/components/DataTable/DataTable";

import useDistributionPresetsTableColumns from "../../hooks/useDistributionPresetsTableColumns";

export default function DistributionPresetsDataTable({ publisher, ...props }) {
	const data = publisher.distribution?.presets || [];

	const columns = useDistributionPresetsTableColumns({ publisher });

	return <DataTable data={data} columns={columns} {...props} />;
}
