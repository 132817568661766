import { object, record, string } from "../../../types/_types";

import { ArticleDelivery } from "./ArticleDelivery";
import { ArticleDistribution } from "./ArticleDistribution";

export const ArticleChannel = object({
	delivery: ArticleDelivery,
	distribution: ArticleDistribution,
});

export const ArticleChannels = record(string(20), ArticleChannel).default({});
