import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import SecondaryActionButton from "../../../ui/components/Buttons/SecondaryActionButton";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import usePricesQuery from "../../../prices/hooks/usePricesQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import usePricesColumns from "./hooks/usePricesColumns";

export default function AdminPricesView({ header, producerId, ...props }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = usePricesQuery({ producerId });
	const columns = usePricesColumns();

	return (
		<>
			<AdminHeader text={t("globalPrices", "Global prices")}>
				<SecondaryActionButton as={Link} to="create">
					{t("addPrices", "Add prices")}
				</SecondaryActionButton>
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../prices/${id}`)}
				sortBy={{
					id: "created",
					desc: true,
				}}
				{...props}
			/>
		</>
	);
}
