import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

import api from "../../../../../api";
import ArticlesInstantSearchSelect from "../../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";
import OpenButton from "../../../../ui/components/OpenButton/OpenButton";

export default function ProductionArticlesAddArticleButton({ production, publisher }) {
	const { t } = useTranslation();

	const { form, onSubmit, watch } = useSimpleForm({
		defaultValues: {
			article: null,
		},
		onSubmit: ({ article }) => {
			return api.productions.addArticle({ article, production });
		},
	});

	const { article } = watch();

	return (
		<OpenButton text={t("addArticle", "Add article")}>
			{({ onClose }) => (
				<ModalPrompt
					size="tiny"
					disabled={!article}
					header={t("addArticle", "Add article")}
					confirmText={t("add", "Add")}
					onClose={onClose}
					onSave={onSubmit}
				>
					<Form form={form}>
						<ArticlesInstantSearchSelect
							name="article"
							placeholder={t("articleName", "Article name")}
							type={articleTypes.AUDIOBOOK}
							fluid
							allowEmpty
							publisher={publisher}
							notProduction={production}
						/>
					</Form>
				</ModalPrompt>
			)}
		</OpenButton>
	);
}
