import { useTranslation } from "react-i18next";

import { languageNames } from "astrid-helpers/src/languages";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useArticleTypeTranslations from "../../articles/hooks/useArticleTypeTranslations";

import useDistributionPresetDropdownOptions from "./useDistributionPresetDropdownOptions";

export default function useDistributionPresetsTableColumns({ publisher }) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "articleTypes",
			Header: t("articleTypes", "Article types"),
			accessor: ({ articleTypes }) =>
				articleTypes.includes("all")
					? t("all", "All")
					: articleTypes.map((type) => articleTypeTranslations[type]).join(", "),
		},
		{
			id: "languages",
			Header: t("languages", "Languages"),
			accessor: ({ languages }) => (languages.includes("all") ? t("all", "All") : languageNames(languages)),
		},
		{
			id: "channels",
			Header: t("channels", "Channels"),
			accessor: ({ channels }) => channels.map(({ name }) => name).join(", "),
		},
		dropdownMenuColumn(useDistributionPresetDropdownOptions({ publisher })),
	];
}
