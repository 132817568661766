import React from "react";
import { useTranslation } from "react-i18next";

import { Narrator } from "astrid-firestore/src/api/narrators/types/Narrator";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminNarratorSettingsForm({ narrator }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Narrator,
		values: {
			ref: narrator.ref,
			name: narrator.name || "",
			languages: narrator.languages || [],
		},
		onSubmit: (data) => {
			return api.narrators.update(data);
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />

				<Form.LanguageSelect name="languages" label={t("languages", "Languages")} multiple />
			</Form.Group>
		</AdminForm>
	);
}
