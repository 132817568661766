import cloneDeep from "clone-deep";
import equal from "fast-deep-equal";

export default class OptimisticUpdate {
	constructor(before, after) {
		this.before = before;
		this.after = after || cloneDeep(before);
		this.type = null;

		// this.applyUpdatedKeys();
	}

	get(key) {
		if (!key) {
			return this;
		}

		return new OptimisticUpdate(this.before?.[key], this.after?.[key]);
	}

	added(key) {
		if (!this.isUpdated()) {
			return [];
		}

		if (this.isType("array")) {
			const beforeIds = this.before?.map((item) => item?.ref?.id).filter(Boolean) || [];

			return (
				this.after?.filter((item) => {
					if (item?.ref?.id) {
						return !beforeIds.includes(item.ref.id);
					}

					return !this.before?.includes(item);
				}) || []
			);
		}

		return this.after?.[key];
	}

	removed(key) {
		if (!this.isUpdated()) {
			return [];
		}

		if (this.isType("array")) {
			const afterIds = this.after?.map((item) => item?.ref?.id).filter(Boolean) || [];

			return (
				this.before?.filter((item) => {
					if (item?.ref?.id) {
						return !afterIds.includes(item.ref.id);
					}

					return !this.after?.includes(item);
				}) || []
			);
		}

		return this.before?.[key];
	}

	isType(type) {
		return this.getType() === type;
	}

	isCreated() {
		return this.isUpdated() && this.after?.ref && !this.after.exists;
	}

	isUpdated() {
		switch (this.getType()) {
			case "array":
			case "object":
				return !equal(this.before, this.after);

			case "primitive":
				return this.before !== this.after;

			default:
				return false;
		}
	}

	getType() {
		if (this.type) {
			return this.type;
		}

		const value = this.before || this.after;

		if (Array.isArray(value)) {
			return (this.type = "array");
		}

		if (typeof value === "object") {
			return (this.type = "object");
		}

		if (value) {
			return (this.type = "primitive");
		}
	}
}
