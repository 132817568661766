import { useTranslation } from "react-i18next";

export default function useTranslatorsDropdownMenuOptions({ publisher }) {
	const { t } = useTranslation();

	return (data) => [
		{
			text: t("view", "View"),
			linkTo: publisher
				? `/publisher/${publisher.id}/admin/translators/${data.id}`
				: `/admin/translators/${data.id}`,
		},
	];
}
