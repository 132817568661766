import Cache from "lru-cache";

import context from "./audioContext";

export const buffers = new Cache({ max: 100 });

export function audioBufferFromArrayBuffer(buffer) {
	return context.decodeAudioData(buffer);
}

export function audioBufferFromURL(url) {
	if (url) {
		if (!buffers.has(url)) {
			buffers.set(
				url,
				fetch(url)
					.then((res) => res.arrayBuffer())
					.then(audioBufferFromArrayBuffer)
					.catch(() => {
						buffers.delete(url);
					}),
			);
		}

		return buffers.get(url);
	}
}

export function audioBufferFromBlob(blob) {
	if (blob) {
		return new Promise((resolve) => {
			const fr = new FileReader();
			fr.onload = async () => resolve(await audioBufferFromArrayBuffer(fr.result));
			fr.readAsArrayBuffer(blob);
		});
	}
}
