import { Segment } from "semantic-ui-react";

import msToTime from "astrid-helpers/src/msToTime";

import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineSteps from "./PipelineSteps";

export default function PipelineStepContent({ step, progress }) {
	return (
		<Accordion.Content active>
			<div style={{ marginBottom: 10 }}>
				<Segment inverted style={{ marginBottom: 7 }}>
					<pre style={{ margin: 0 }}>
						<>
							{`Execution time: ${msToTime(progress[step.key].executionTime || 0)}`}
							<br />
							{`Speed: ${progress[step.key].speed?.toFixed(3) || 0} Mbit/s`}
							<br />
							{progress[step.key]?.error && `Error: ${progress[step.key].error}`}
						</>
					</pre>
				</Segment>

				{step.steps && <PipelineSteps steps={step.steps} progress={progress} />}
			</div>
		</Accordion.Content>
	);
}
