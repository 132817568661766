import { Header } from "semantic-ui-react";

import React from "react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminNarratorMenuOptions from "../hooks/useAdminNarratorMenuOptions";

export default function AdminNarratorHeader({ narrator }) {
	const menuOptions = useAdminNarratorMenuOptions({ narrator });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{narrator.name}</Header>
		</AdminPageHeader>
	);
}
