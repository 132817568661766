import { Card, Header, Icon } from "semantic-ui-react";

import React, { useState } from "react";

import Flex from "../../../../../components/Flex/Flex";
import ArtifactAudioPlayer from "../../../../artifacts/components/ArtifactAudioPlayer/ArtifactAudioPlayer";

import AdminPageDropdownMenu from "../../../components/AdminPage/AdminPageDropdownMenu";
import AdminPageMenu from "../../../components/AdminPage/AdminPageMenu";

import useAdminArtifactDropdownMenuOptions from "../hooks/useAdminArtifactDropdownMenuOptions";
import useAdminArtifactMenuOptions from "../hooks/useAdminArtifactMenuOptions";

export default function AdminArtifactHeader({ artifact }) {
	const [showAudioPlayer, setShowAudioPlayer] = useState(false);

	const menuOptions = useAdminArtifactMenuOptions({ artifact });

	const dropdownMenuOptions = useAdminArtifactDropdownMenuOptions({ artifact });

	const hasAudio = artifact.files.some((file) => file.contentType.includes("audio"));

	return (
		<Card fluid>
			<Card.Content>
				<Flex gap={10}>
					<Flex justifyContent="flex-start" gap={10}>
						<Header style={{ margin: 0 }}>{artifact.name}</Header>
					</Flex>

					{hasAudio && (
						<div>
							<Icon
								link
								name="play"
								onClick={() => setShowAudioPlayer((showAudioPlayer) => !showAudioPlayer)}
							/>
						</div>
					)}

					{dropdownMenuOptions && <AdminPageDropdownMenu options={dropdownMenuOptions} />}
				</Flex>
			</Card.Content>

			{showAudioPlayer && (
				<Card.Content style={{ padding: 0 }}>
					<ArtifactAudioPlayer artifact={artifact} />
				</Card.Content>
			)}

			{menuOptions && (
				<Card.Content>
					<AdminPageMenu options={menuOptions} />
				</Card.Content>
			)}
		</Card>
	);
}
