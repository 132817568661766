import { useEffect, useRef, useState } from "react";

import Select from "../Select/Select";

import InstantSearchData from "./InstantSearchData";

function InstantSearchSelectHits({ data, getOption, setSearchQuery, nbHits, nbPages, setPage, ...props }) {
	const getOptionRef = useRef(getOption);

	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (data?.length > 0) {
			setOptions((options) => {
				const newOptions = data
					.map(getOptionRef.current)
					.filter(({ value }) => !options.find((option) => option.value === value));

				return [...options, ...newOptions].sort((a, b) => a.text.localeCompare(b.text));
			});
		}
	}, [data]);

	return <Select search options={options} disabled={false} {...props} />;
}

export default function InstantSearchSelect({ configure, value, getOption, ...props }) {
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		setSearchQuery("");
	}, [value]);

	return (
		<InstantSearchData
			value={value}
			searchQuery={searchQuery}
			configure={{
				hitsPerPage: 10,
				...configure,
			}}
			{...props}
		>
			<InstantSearchSelectHits
				getOption={getOption}
				onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
			/>
		</InstantSearchData>
	);
}
