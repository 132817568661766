import { Divider, Grid, Header, Label, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import Flex from "../../../../components/Flex/Flex";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import ArticleCoverImage from "../../components/ArticleCoverImage/ArticleCoverImage";
import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";
import articleTypeIcons from "../../utils/articleTypeIcons";

import ArticleDetail from "./components/ArticleDetail";
import ArticleLabel from "./components/ArticleLabel";

export default function ArticleView({ article, organization }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const {
		id,
		synopsis,
		author,
		publisher,
		imprint,
		language,
		length,
		isbn,
		narrator,
		originalLanguage,
		originalName,
		serie,
		serieNumber,
		themaCodes,
		translator,
		type,
	} = article;

	const articleTypes = useArticleTypeTranslations();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const dropdownOptions = [
		{
			text: t("editInOrganization", "Edit in {{organization}}", { organization: publisher?.name }),
			linkTo: `/publisher/${publisher?.id}/admin/articles/${article?.id}`,
			hidden: !hasRight("articles.view") || !organization || !hasFlatteningFeature,
		},
	];

	return (
		<Segment padded>
			<Grid style={{ maxWidth: 1400, margin: "0 auto" }}>
				<Grid.Row>
					<Grid.Column width={4}>
						<ArticleCoverImage article={article} style={{ borderRadius: 10 }} />
					</Grid.Column>

					<Grid.Column width={11}>
						<Flex style={{ marginBottom: "1em" }}>
							<Header as="h2" style={{ margin: 0 }}>
								{article?.name}
							</Header>

							<DropdownMenu iconStyle={{ fontSize: 15 }} options={dropdownOptions} />
						</Flex>

						<Flex style={{ marginTop: 5 }} gap={5} justifyContent="flex-start">
							<ArticleDetail label={t("author", "Author")} value={author} />

							<ArticleDetail label={t("publisher", "Publisher")} value={publisher?.name} />

							<ArticleDetail label={t("imprint", "Imprint")} value={imprint?.name} />

							<ArticleDetail
								label={t("serie", "Series")}
								value={serie?.name && `${serie?.name} (Nr. ${serieNumber})`}
							/>
						</Flex>

						<Divider />

						<Flex justifyContent="flex-start" gap={4}>
							<ArticleLabel icon="book" value={isbn} />

							<ArticleLabel icon="language" value={languageName(language)} />

							<ArticleLabel icon={articleTypeIcons[type]} value={articleTypes[type]} />

							<ArticleLabel icon="clock outline" value={length} />
						</Flex>

						<>
							<Divider />
							<div>{synopsis}</div>
						</>

						<Flex style={{ flexDirection: "column", marginTop: 10 }} alignItems="flex-start" gap={5}>
							<ArticleDetail
								label={t("originalLanguage", "Original language")}
								value={languageName(originalLanguage)}
							/>

							<ArticleDetail label={t("titleOriginal", "Original title")} value={originalName} />

							<ArticleDetail label={t("translator", "Translator")} value={translator} />

							<ArticleDetail label={t("reader", "Narrator")} value={narrator} />
						</Flex>

						{themaCodes.length > 0 && (
							<>
								<Divider />

								<strong>{t("themaCodes", "Thema codes")}</strong>

								<Flex style={{ marginTop: 10 }} justifyContent="flex-start" gap={5}>
									{themaCodes.map((code) => (
										<Label style={{ marginLeft: 0 }} key={code}>
											{code}
										</Label>
									))}
								</Flex>
							</>
						)}

						<Divider />

						<strong>{t("distributionChannels", "Distribution channels")}</strong>

						<Flex style={{ flexDirection: "column", marginTop: 5 }} alignItems="flex-start">
							<a href="https://www.storytel.com" target="_blank" rel="noreferrer">
								Storytel (2023-01-02)
							</a>

							<a href="https://www.bookbeat.com" target="_blank" rel="noreferrer">
								BookBeat (2024-01-23)
							</a>

							<a href="https://www.bokus.com" target="_blank" rel="noreferrer">
								Bokus (2024-03-02)
							</a>
						</Flex>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
}
