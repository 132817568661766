import { Dropdown, Icon } from "semantic-ui-react";

import React, { useState } from "react";
import { Link } from "react-router-dom";

import AlertModal from "../AlertModal/AlertModal";
import MenuItemContent from "../Menu/components/MenuItemContent";

export default function DropdownMenu({
	icon = "ellipsis vertical",
	direction = "left",
	loading: initialLoading = false,
	children,
	text,
	options,
	size,
	fitted,
	iconStyle,
	...props
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const isLoading = loading || initialLoading;

	return (
		<>
			<Dropdown
				floating
				scrolling
				icon={text ? icon : false}
				direction={direction}
				trigger={
					!text && (
						<Icon
							style={iconStyle}
							size={size}
							fitted={fitted}
							loading={isLoading}
							name={isLoading ? "spinner" : icon}
							onClick={(e) => e.preventDefault()}
						/>
					)
				}
				text={
					text && (
						<div
							style={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{text}
						</div>
					)
				}
				{...props}
			>
				<Dropdown.Menu style={{ maxHeight: "80vh" }}>
					{children && children({ setLoading, setError })}

					{options
						?.filter((option) => option && !option.hidden)
						.map(
							({
								to,
								linkTo,
								icon,
								text,
								content,
								children,
								loading,
								label,
								labelColor,
								action,
								onClick,
								...props
							}) => (
								<Dropdown.Item
									as={to || linkTo ? Link : "div"}
									to={to || linkTo}
									key={text}
									onClick={
										action
											? async () => {
													setLoading(true);
													setError(null);

													try {
														await action();
													} catch (error) {
														console.error(error);
														setError(error);
													}

													setLoading(false);
											  }
											: onClick
									}
									{...props}
								>
									<MenuItemContent
										icon={icon}
										text={text}
										content={content}
										loading={loading}
										label={label}
										labelColor={labelColor}
									>
										{children}
									</MenuItemContent>
								</Dropdown.Item>
							),
						)}
				</Dropdown.Menu>
			</Dropdown>

			{error && <AlertModal text={error.message || error} onClose={() => setError(null)} />}
		</>
	);
}
