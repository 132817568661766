import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function AdminAuthorArticlesView({ author, organization }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("articles", "Articles")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArticlesCollectionDataTable publisher={organization} author={author} />
			</Card.Content>
		</Card>
	);
}
