import { Button } from "semantic-ui-react";

const iconStyles = {
	paddingLeft: 16,
	paddingRight: 16,
};

export default function PrimaryActionButton({ color = "blue", icon, ...props }) {
	return <Button color={color} icon={icon} style={icon && iconStyles} {...props} />;
}
