import { Table } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import ArticleDistributionDatesFormInputs from "./ArticleDistributionDatesFormInputs";

export default function ArticleDistributionDatesTable({ channels }) {
	const { t } = useTranslation();

	return (
		<Table>
			<Table.Header>
				<Table.Row>
					{channels && <Table.HeaderCell>{t("channel", "Channel")}</Table.HeaderCell>}
					<Table.HeaderCell>{t("announceAt", "Announce")}</Table.HeaderCell>
					<Table.HeaderCell>{t("releaseAt", "Release")}</Table.HeaderCell>
					<Table.HeaderCell>{t("takedownAt", "Unpublish")}</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row verticalAlign="top">
					{channels && <Table.Cell verticalAlign="middle">{t("allChannels", "All channels")}</Table.Cell>}
					<ArticleDistributionDatesFormInputs name="distribution" />
				</Table.Row>
				{channels?.map((channel) => {
					return (
						<Table.Row key={channel.id} verticalAlign="top">
							<Table.Cell verticalAlign="middle">{channel.name}</Table.Cell>
							<ArticleDistributionDatesFormInputs name={`channels.${channel.id}.distribution`} />
						</Table.Row>
					);
				})}
			</Table.Body>
		</Table>
	);
}
