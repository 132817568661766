import { Accordion, Message, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import db from "astrid-firebase/src/db";
import { canGrabSession } from "astrid-firestore/src/api/sessions/grabSession";
import { useDocumentData } from "astrid-firestore/src/hooks";
import GrabSessionButton from "astrid-web/src/features/calendar/components/SessionModal/components/GrabSessionButton";

import Button from "../../../ui/components/Buttons/Button";
import PrimaryActionButton from "../../../ui/components/Buttons/PrimaryActionButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

import CancelSessionForm from "./components/CancelSessionForm";
import EditSession from "./components/EditSession";
import ProductionInformation from "./components/ProductionInformation";
import SessionInformation from "./components/SessionInformation";

export default function SessionModal({ session, onClose, sessions }) {
	const { t } = useTranslation();
	const user = useProfile();
	const hasRight = useHasRight();

	const [isCancelling, setIsCancelling] = useState(false);
	const [error, setError] = useState(null);

	const isSessionRecorder = user.id === session.recorder;
	const isSessionReader = session.reader.includes(user.id);
	const isSessionManager = session.managerIds?.includes(user.id);

	const hasSessionEnded = new Date() > session.end.toDate();

	const canEditSessions =
		hasRight("calendar.editAllSessions") ||
		(hasRight("calendar.editOwnSessions") && session.managerIds?.includes(user.id));

	const [production, loadingProduction, errorProduction] = useDocumentData(
		session?.production && db.collection("productions").doc(session.production),
	);

	const onClickBack = () => {
		setIsCancelling(false);
	};

	return (
		<Modal size="small" open closeOnDimmerClick onClose={onClose}>
			<LoadingContext data={production} loading={loadingProduction} error={errorProduction}>
				<Modal.Header>{`${t("recSession", "Session")} - ${production?.title}`}</Modal.Header>

				{!isCancelling ? (
					<>
						<Modal.Content>
							<Accordion fluid>
								<SessionInformation session={session} production={production} />

								{canEditSessions && !hasSessionEnded && (
									<EditSession session={session} production={production} sessions={sessions} />
								)}
								<ProductionInformation session={session} production={production} />
							</Accordion>

							{error && <Message negative>{t("errorMessageHeader", "Something went wrong")}</Message>}
						</Modal.Content>

						<Modal.Actions>
							<PrimaryActionButton color={undefined} onClick={onClose}>
								{t("close")}
							</PrimaryActionButton>

							{canEditSessions && !hasSessionEnded && (
								<PrimaryActionButton color="red" onClick={() => setIsCancelling(true)}>
									{t("cancelSession", "Cancel session")}
								</PrimaryActionButton>
							)}

							{(canEditSessions || isSessionRecorder || isSessionManager || isSessionReader) && (
								<Button as={Link} to={"/production/" + session.production}>
									{t("toProduction", "To production")}
								</Button>
							)}

							{hasRight("studioSession.connect") && canGrabSession({ session, user, production }) && (
								<GrabSessionButton session={session} production={production} setError={setError} />
							)}
						</Modal.Actions>
					</>
				) : (
					<CancelSessionForm onClickBack={onClickBack} onClose={onClose} session={session} />
				)}
			</LoadingContext>
		</Modal>
	);
}
