import { useTranslation } from "react-i18next";

import distributionStatuses from "astrid-firestore/src/api/distribution/constants/distributionStatuses";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import DistributionsInstantSearchDataTable from "../../../distribution/components/DistributionsInstantSearchDataTable/DistributionsInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminDistributionsScheduledView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("scheduledPlural", "Scheduled")}>{searchInput}</AdminHeader>

			<DistributionsInstantSearchDataTable
				organization={organization}
				statuses={[
					distributionStatuses.PENDING,
					distributionStatuses.SCHEDULED,
					distributionStatuses.INITIALIZED,
					distributionStatuses.RUNNING,
				]}
				searchQuery={searchQuery}
				visibleColumns={["status", "channel.name", "article.name", "pipeline.name", "created", "menu"]}
			/>
		</>
	);
}
