import arrayUnion from "../utils/arrayUnion";
import runBatch from "../utils/runBatch";
import runTransaction from "../utils/runTransaction";

import FirestoreTransaction from "./FirestoreTransaction";

export default function createFirestoreUtils(firebase, { debug } = {}) {
	return {
		arrayUnion(value, union) {
			return arrayUnion(firebase, value, union);
		},

		runBatch(callback, transaction) {
			return runBatch(firebase, callback, transaction);
		},

		runTransaction(refs, callback, transaction) {
			return runTransaction(firebase, refs, callback, transaction);
		},

		createTransaction(transaction) {
			return new FirestoreTransaction(firebase, { transaction, debug });
		},

		delete() {
			return firebase.firestore.FieldValue.delete();
		},

		documentId() {
			return firebase.firestore.FieldPath.documentId();
		},
	};
}
