import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateTranslatorButton from "../../../translators/components/CreateTranslatorForm/CreateTranslatorButton";
import TranslatorsInstantSearchDataTable from "../../../translators/components/TranslatorsInstantSearchDataTable/TranslatorsInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminTranslatorsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("translators", "Translators")}>
				{searchInput}

				{organization && <CreateTranslatorButton organization={organization} />}
			</AdminHeader>

			<TranslatorsInstantSearchDataTable searchQuery={searchQuery} publisher={organization} />
		</>
	);
}
