import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { DistributionPreset } from "astrid-firestore/src/api/distribution/types/DistributionPreset";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import ArticleTypeSelect from "../../../articles/components/ArticleTypeSelect/ArticleTypeSelect";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionPresetForm({ publisher, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error, setValue, watch } = useSimpleForm({
		schema: DistributionPreset,
		values: {
			languages: [],
			channels: [],
			articleTypes: [],
		},
		onSubmit: (data) => {
			return api.organizations.addDistributionPreset({
				publisher,
				...data,
			});
		},
		onSuccess: onClose,
	});

	const { languages, channels } = watch();

	const additionalOptions = [{ key: "all", value: "all", text: t("all", "All") }];

	const onChangeOptions =
		(name) =>
		({ value }) => {
			if (value.length > 1 && value.includes("all")) {
				const last = value[value.length - 1];

				setValue(name, last === "all" ? ["all"] : value.filter((v) => v !== "all"));
			}
		};

	const filterDistributionChannels = useCallback(
		({ data }) => {
			const { acceptedLanguages } = data;

			return (
				acceptedLanguages.length === 0 || languages.every((language) => acceptedLanguages.includes(language))
			);
		},
		[languages],
	);

	useEffect(() => {
		const filteredChannels = channels.filter((channel) => filterDistributionChannels({ data: channel }));

		if (filteredChannels.length !== channels.length) {
			setValue("channels", filteredChannels);
		}
	}, [filterDistributionChannels, channels, setValue]);

	return (
		<ModalPrompt
			header={t("addPreset", "Add preset")}
			size="tiny"
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
			error={error}
		>
			<Form form={form}>
				<Form.Field name="articleTypes">
					<ArticleTypeSelect
						label={t("articleTypes", "Article types")}
						additionalOptions={additionalOptions}
						onChangeOptions={onChangeOptions("articleTypes")}
						multiple
						clearable
					/>
				</Form.Field>

				<Form.LanguageSelect
					name="languages"
					label={t("languages", "Languages")}
					additionalOptions={additionalOptions}
					onChangeOptions={onChangeOptions("languages")}
					multiple
					clearable
				/>

				<Form.CollectionSelect
					name="channels"
					label={t("channels", "Channels")}
					collection={api.distributionChannels}
					filterOptions={filterDistributionChannels}
					disabled={languages.length === 0}
					multiple
					clearable
				/>
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
