import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateNarratorButton from "../../../narrators/components/CreateNarratorForm/CreateNarratorButton";
import NarratorsCollectionDataTable from "../../../narrators/components/NarratorsCollectionDataTable/NarratorsCollectionDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminNarratorsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("readers", "Narrators")}>
				{searchInput}

				<CreateNarratorButton organization={organization} />
			</AdminHeader>

			<NarratorsCollectionDataTable
				organization={organization}
				searchQuery={searchQuery}
				getQuery={(query) => query.where("publisherIds", "array-contains", organization.id)}
			/>
		</>
	);
}
