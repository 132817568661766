import distributionStatuses from "astrid-firestore/src/api/distribution/constants/distributionStatuses";

const distributionStatusColors = {
	[distributionStatuses.PENDING]: "blue",
	[distributionStatuses.SCHEDULED]: "blue",
	[distributionStatuses.INITIALIZED]: "yellow",
	[distributionStatuses.RUNNING]: "yellow",
	[distributionStatuses.CANCELLED]: "black",
	[distributionStatuses.COMPLETED]: "green",
	[distributionStatuses.FAILED]: "red",
};

export default distributionStatusColors;
