import nameCase from "astrid-helpers/src/nameCase";

import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Narrator } from "./types/Narrator";

export default class NarratorsCollection extends FirestoreCollection {
	static collectionName = "narrators";
	static Schema = Narrator;

	preProcess(data) {
		if (data.name) {
			data.name = nameCase(data.name);
			data.nameLowerCase = data.name.toLowerCase();
		}

		return data;
	}
}
