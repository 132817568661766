import { useTranslation } from "react-i18next";

import ArticleDistributionChannels from "../../../articles/components/ArticleDistributionChannels/ArticleDistributionChannels";
import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

import AdminArticleDistributionDatesForm from "./components/AdminArticleDistributionDatesForm";

export default function AdminArticleDistributionView({ article, publisher }) {
	const { t } = useTranslation();

	return (
		<>
			<AdminCard header={t("channels", "Channels")}>
				<ArticleDistributionChannels article={article} publisher={publisher} />
			</AdminCard>

			<AdminArticleDistributionDatesForm article={article} publisher={publisher} />

			<AdminCard header={t("distributions", "Distributions")}>
				<DistributionsCollectionDataTable article={article} />
			</AdminCard>
		</>
	);
}
