import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

export default function useSuperAdminDashboardStatistics() {
	const { t } = useTranslation();

	return [
		{
			label: t("needsApproval", "Needs approval"),
			link: "approve/costs",
			total: 51,
			details: [
				{
					text: t("costs", "Costs"),
					icon: <Icon name="coins" />,
					amount: 18,
				},

				{
					text: t("prices", "Prices"),
					icon: <Icon name="tag" />,
					amount: 6,
				},

				{
					text: t("offers", "Offers"),
					icon: <Icon name="badge percent" />,
					amount: 2,
				},
				{
					text: t("agreements", "Agreements"),
					icon: <Icon name="file alternate" />,
					amount: 22,
				},
			],
		},
		{
			label: t("distributions", "Distributions"),
			link: "distributions",
			total: 34,
			details: [
				{ text: t("completedPlural", "Completed"), icon: "check", amount: 19 },
				{
					text: t("scheduledPlural", "Scheduled"),
					icon: "calendar clock",
					amount: 10,
				},
				{ text: t("abortedPlural", "Aborted"), icon: "x", amount: 5 },
			],
		},
		{
			label: t("articles", "Articles"),
			link: "articles",
			total: 66,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: 33,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: 20,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: 13,
				},
			],
		},
		{
			label: t("users", "Users"),
			link: "users/narrator",
			total: 120,
			details: [
				{ text: t("narrators", "Narrators"), icon: <Icon name="microphone" />, amount: 70 },
				{ text: t("publishers", "Publishers"), icon: <Icon name="book user" />, amount: 30 },
				{ text: t("producers", "Producers"), icon: <Icon name="cogs" />, amount: 30 },
				{ text: t("unassigned", "Unassigned"), icon: <Icon name="user circle outline" />, amount: 20 },
			],
		},
		{
			label: t("producers", "Producers"),
			link: "producers/list",
			total: 30,
		},
		{
			label: t("publishers", "Publishers"),
			link: "publishers/list",
			total: 23,
		},
	];
}
