import { useCallback, useMemo } from "react";

import storage from "astrid-firebase/src/storage";
import { StorageFile } from "astrid-firestore/src/types/StorageFile";
import arrayChunk from "astrid-helpers/src/arrayChunk";
import { isLive } from "astrid-helpers/src/env";
import { getFileDuration } from "astrid-helpers/src/fileReader";

export default function useBucket(bucket) {
	const ref = useMemo(() => bucket && storage.refFromURL(`gs://${isLive ? "" : "stage-"}${bucket}`), [bucket]);

	const write = useCallback((file, data, meta) => ref.child(file).put(data, meta), [ref]);

	const putFile = useCallback(
		async (path, sourceFile) => {
			const fileRef = ref.child(path);

			const [duration] = await Promise.all([getFileDuration(sourceFile), fileRef.put(sourceFile)]);

			const [url, metaData] = await Promise.all([fileRef.getDownloadURL(), fileRef.getMetadata()]);

			return StorageFile.parse({ url, duration, ...metaData });
		},
		[ref],
	);

	const deleteFile = useCallback(
		async (file) => {
			try {
				return await ref.child(file?.fullPath || file).delete();
			} catch (error) {
				return false;
			}
		},
		[ref],
	);

	const deleteFiles = useCallback(
		(files) => {
			return Promise.all(files.map((file) => deleteFile(file.fullPath)));
		},
		[ref, deleteFile],
	);

	const putFiles = useCallback(
		async (path, sourceFiles, getName = (file) => file.name) => {
			const result = [];

			const files = sourceFiles.map((file) => ({
				path: path ? `${path}/${getName(file)}` : getName(file),
				file,
			}));

			try {
				for (const chunk of arrayChunk(files, 10)) {
					const chunkResult = await Promise.all(chunk.map(({ path, file }) => putFile(path, file)));

					result.push(...chunkResult);
				}
			} catch (error) {
				await deleteFiles(result);

				throw error;
			}

			return result;
		},
		[ref, putFile, deleteFiles],
	);

	return useMemo(
		() => ({ ref, write, putFile, putFiles, deleteFile, deleteFiles }),
		[ref, write, putFile, putFiles, deleteFile, deleteFiles],
	);
}
