import { useTranslation } from "react-i18next";

export default function useAuthorsDropdownMenuOptions({ publisher }) {
	const { t } = useTranslation();

	return (data) => [
		{
			text: t("view", "View"),
			linkTo: publisher ? `/publisher/${publisher.id}/admin/authors/${data.id}` : `/admin/authors/${data.id}`,
		},
	];
}
