import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";
import AddArticlesToTitleButton from "../../../titles/components/AddArticlesToTitleForm/AddArticlesToTitleButton";

export default function AdminTitleArticlesView({ title }) {
	const { t } = useTranslation();

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Card.Header>{t("articles", "Articles")}</Card.Header>
				</Card.Content>
				<Card.Content>
					<ArticlesCollectionDataTable title={title} showSync enableSync />
				</Card.Content>
				<Card.Content extra>
					<AddArticlesToTitleButton title={title} />
				</Card.Content>
			</Card>
		</>
	);
}
