import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import ArtifactsCollectionDataTable from "../../../artifacts/components/ArtifactsCollectionDataTable/ArtifactsCollectionDataTable";
import CreateArtifactButton from "../../../artifacts/components/CreateArtifactForm/CreateArtifactButton";

export default function AdminArticleArtifactsView({ article, organization }) {
	const { t } = useTranslation();

	const defaultName = `${article.name}${
		article.artifactIds?.length > 0 ? ` (${article.artifactIds.length + 1})` : ""
	}`;

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("artifacts", "Artifacts")}</Card.Header>
			</Card.Content>

			<Card.Content>
				<ArtifactsCollectionDataTable article={article} />
			</Card.Content>

			<Card.Content extra>
				<CreateArtifactButton article={article} publisher={organization} defaultName={defaultName} />
			</Card.Content>
		</Card>
	);
}
