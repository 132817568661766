import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { AudioWavStorageFile } from "../../../types/StorageFile";
import { array, arrayMinMax, boolean, enumValues, nullable, number, object, string } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { ProductionRef } from "../../productions/types/Production";

import artifactMarkerTypes from "../constants/artifactMarkerTypes";

export const artifactMarkerType = enumValues(artifactMarkerTypes);

export const artifactMarker = object({
	id: number(),
	position: number(),
	text: string(),
	type: artifactMarkerType,
});

export const Artifact = FirestoreDocument.extend({
	article: ArticleRef,
	files: arrayMinMax(AudioWavStorageFile, 1),
	markers: array(artifactMarker),
	isDefault: boolean(),
	name: string(3),
	producer: nullable(OrganizationRef),
	production: nullable(ProductionRef),
	publisher: nullable(OrganizationRef),
	size: number().default(0),
}).merge(FirestoreLifecycle);
