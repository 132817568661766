import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { MetaData } from "../../../types/MetaData";
import {
	array,
	boolean,
	duration,
	enumValues,
	isbn,
	literal,
	nullable,
	string,
	timestamp,
	union,
} from "../../../types/_types";

import { ArtifactRef } from "../../artifacts/types/ArtifactRef";
import { NarratorRef } from "../../narrators/types/Narrator";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { ProductionRef } from "../../productions/types/Production";
import { TitleRef } from "../../titles/types/Title";

import articleTypes from "../constants/articleTypes";

import { ArticleBundleRef } from "./ArticleBundleRef";
import { ArticleChannels } from "./ArticleChannel";
import { ArticleDelivery } from "./ArticleDelivery";
import { ArticleDistribution } from "./ArticleDistribution";
import { ArticlePages } from "./ArticlePages";
import { ArticleRef } from "./ArticleRef";

export const articleType = enumValues(articleTypes);

export const ArticleMetaData = MetaData.extend({
	duration: nullable(duration()),
	isbn: nullable(isbn()),
	linkedArticle: nullable(ArticleRef),
	narrator: nullable(string()),
	pages: nullable(ArticlePages),
	type: articleType,
});

export const ArticleDeliveryMetaData = ArticleMetaData.omit({
	authorIds: true,
	authors: true,
	translatorIds: true,
	translators: true,
});

export const Article = FirestoreDocument.extend({
	artifact: nullable(ArtifactRef),
	bundle: nullable(ArticleBundleRef),
	channels: ArticleChannels,
	channelIds: array(string()),
	delivery: ArticleDelivery,
	deliveryDate: nullable(timestamp()),
	distribution: ArticleDistribution,
	narratorIds: array(string()),
	narrators: array(NarratorRef),
	producer: nullable(OrganizationRef),
	production: nullable(ProductionRef),
	publisher: OrganizationRef,
	title: union([literal(false), TitleRef]),
	titleSync: boolean(),
})
	.merge(FirestoreLifecycle)
	.merge(ArticleMetaData);
