import nameCase from "astrid-helpers/src/nameCase";

import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Author } from "./types/Author";

export default class AuthorsCollection extends FirestoreCollection {
	static collectionName = "authors";
	static Schema = Author;

	preProcess(data) {
		if (data.name) {
			data.name = nameCase(data.name);
			data.nameLowerCase = data.name.toLowerCase();
		}

		return data;
	}
}
