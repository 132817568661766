import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import Button from "../../../ui/components/Buttons/Button";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import themeColors from "../../../ui/constants/themeColors";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";
import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import useImprintArticleStatistics from "../../../imprints/hooks/useImprintArticleStatistics";

import useAdminArticlesDashboardMenuOptions from "./hooks/useAdminArticlesDashboardMenuOptions";

export default function AdminArticlesDashboardView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [imprints, loading, error] = api.imprints.useAll({ publisher: organization });

	const [activeImprintId, setActiveImprintId] = useState();

	const menuOptions = useAdminArticlesDashboardMenuOptions(imprints, activeImprintId, setActiveImprintId);

	const delivered = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "delivered",
		label: t("deliveredPlural", "Delivered"),
		color: "green",
		detailColor: themeColors.GREEN,
		link: "articles/delivered",
		organization,
	});

	const deliveryNeeded = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "deliveryNeeded",
		label: t("deliveryNeeded", "Delivery needed"),
		color: "orange",
		detailColor: themeColors.ORANGE,
		link: "articles/deliveryNeeded",
		organization,
	});

	const scheduled = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "scheduled",
		label: t("scheduled", "Scheduled"),
		color: "blue",
		detailColor: themeColors.BLUE,
		link: "articles/scheduled",
		organization,
	});

	const notDelivered = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "notDelivered",
		label: t("notDeliveredPlural", "Not delivered"),
		link: "articles/notDelivered",
		organization,
	});

	const statistics = [...delivered, ...scheduled, ...deliveryNeeded, ...notDelivered];

	return (
		<LoadingContext data={imprints} loading={loading} error={error}>
			<Dashboard menuOptions={menuOptions} statistics={statistics}>
				<ArticlesInstantSearchDataTable
					publisher={organization}
					configure={{
						hitsPerPage: 10,
					}}
					visibleColumns={["name", "status", "type", "isbn", "created", "menu"]}
					footer={
						<Button basic onClick={() => navigate("all")}>
							{t("allArticles", "All articles")}
						</Button>
					}
				/>
			</Dashboard>
		</LoadingContext>
	);
}
