import { Button } from "semantic-ui-react";

const iconStyles = {
	paddingLeft: 16,
	paddingRight: 16,
};

export default function SecondaryActionButton({ icon, ...props }) {
	return <Button basic primary style={icon && iconStyles} {...props} />;
}
