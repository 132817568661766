import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import useTranslatorsDropdownMenuOptions from "./useTranslatorsDropdownMenuOptions";

export default function useTranslatorsTableColumns({ publisher }) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			disableSortBy: true,
			Header: t("Name", "Name"),
		},
		timestampColumn({
			id: "created",
			Header: t("created", "Created"),
			time: false,
		}),
		dropdownMenuColumn(useTranslatorsDropdownMenuOptions({ publisher })),
	];
}
