import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import api from "../../../../../api";

export default function useAdminArticleMenuOptions({ article }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [artifactsCount, artifactsCountLoading] = api.artifacts.useCount({ article });

	const [productionsCount, productionsCountLoading] = api.productions.useCount((query) =>
		query.where("articleIds", "array-contains", article.id),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(article.id),
			text: t("metaData", "Meta data"),
		},
		{
			to: "artifacts",
			active: pathname.endsWith("/artifacts"),
			text: t("artifacts", "Artifacts"),
			label: artifactsCount || article.artifactIds?.length,
			loading: artifactsCountLoading,
		},
		{
			to: "bundles",
			active: pathname.endsWith("/bundles"),
			text: t("bundles", "Bundles"),
			label: article.bundle ? 1 : undefined,
		},
		{
			to: "distribution",
			active: pathname.endsWith("/distribution"),
			text: t("distribution", "Distribution"),
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions"),
			label: productionsCount || undefined,
			loading: productionsCountLoading,
		},
		{
			to: "versions",
			active: pathname.endsWith("/versions"),
			text: t("versions", "Versions"),
		},
	];
}
