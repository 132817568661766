import { Icon, Label, Loader, Menu } from "semantic-ui-react";

import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

function MenuItem({ label, labelColor = "blue", children, active, loading, ...props }) {
	return (
		<Menu.Item as={Link} active={!!active} {...props}>
			{!loading && label !== undefined && <Label color={labelColor}>{label}</Label>}

			{children}

			{loading && <Loader as={Icon} inline active size="tiny" />}
		</Menu.Item>
	);
}

function getActive({ path, active, pathname, options }) {
	return active !== undefined
		? active
		: pathname.includes(path) || options?.find(({ path }) => pathname.includes(path));
}

export default function VerticalMenu({ options }) {
	const { pathname } = useLocation();

	return (
		<Menu fluid vertical pointing secondary>
			{options
				.filter(({ hidden }) => !hidden)
				.map(({ name, path, label, labelColor, options, active, loading }) => {
					active = getActive({ path, active, pathname, options });

					return (
						<Fragment key={path}>
							<MenuItem to={path} label={label} labelColor={labelColor} active={active} loading={loading}>
								{name}
							</MenuItem>

							{options && active && (
								<Menu.Menu style={{ marginLeft: 20 }}>
									{options.map(
										({ name, path, label, labelColor, active, hidden, loading }) =>
											!hidden && (
												<MenuItem
													end="true"
													to={path}
													key={path}
													label={label}
													loading={loading}
													labelColor={labelColor}
													active={getActive({ path, active, pathname })}
												>
													{name}
												</MenuItem>
											),
									)}
								</Menu.Menu>
							)}
						</Fragment>
					);
				})}
		</Menu>
	);
}
