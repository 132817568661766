import { useTranslation } from "react-i18next";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useDistributionPipelineDropdownMenuOptions from "../../../distribution/hooks/useDistributionPipelineDropdownMenuOptions";
import Pipeline from "../../../pipelines/components/Pipeline/Pipeline";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminDistributionPipelineView({ distribution }) {
	const { t } = useTranslation();

	const dropdownMenuOptions = useDistributionPipelineDropdownMenuOptions({ channel: distribution.channel });

	return (
		<AdminCard header={t("pipeline", "Pipeline")} dropdownMenuOptions={dropdownMenuOptions(distribution.pipeline)}>
			<LoadingContext data={distribution.pipeline?.steps}>
				<Pipeline distribution={distribution} />
			</LoadingContext>
		</AdminCard>
	);
}
