import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import ArticleDistributionDatesTable from "../../../../articles/components/ArticleDistributionDatesTable/ArticleDistributionDatesTable";
import useArticleDistributionChannels from "../../../../articles/hooks/useArticleDistributionChannels";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleDistributionDatesForm({ publisher, article }) {
	const { t } = useTranslation();

	const [channels, loading, error] = useArticleDistributionChannels({ publisher, article });

	const form = useSimpleForm({
		schema: Article,
		defaultValues: {
			ref: article.ref,
			distribution: article.distribution || {},
			channels: Object.entries(article.channels || {}).reduce(
				(acc, [id, { distribution }]) => ({
					...acc,
					[id]: { distribution },
				}),
				{},
			),
		},
		onSubmit: ({ ref, distribution, channels }) => {
			return api.articles.update(
				{
					ref,
					distribution,
					...Object.entries(channels).reduce(
						(acc, [channelId, { distribution }]) => ({
							...acc,
							[`channels.${channelId}.distribution`]: distribution,
						}),
						{},
					),
				},
				{ version: false },
			);
		},
	});

	return (
		<AdminForm header={t("dates", "Dates")} {...form}>
			<LoadingContext data={channels} loading={loading} error={error}>
				<ArticleDistributionDatesTable channels={channels} form={form} />
			</LoadingContext>
		</AdminForm>
	);
}
