import { useState } from "react";
import { useTranslation } from "react-i18next";

import SecondaryActionButton from "../../../ui/components/Buttons/SecondaryActionButton";

import AddUserForm from "./AddUserForm";

export default function AddUserButton({ organization, reader }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<SecondaryActionButton onClick={() => setOpen(true)}>
				{reader ? t("createReader", "Create narrator") : t("addUser", "Add user")}
			</SecondaryActionButton>

			{open && <AddUserForm organization={organization} onClose={() => setOpen(false)} reader={reader} />}
		</>
	);
}
