import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../../../ui/components/Buttons/Button";

import ProductionsCollectionDataTable from "../../../productions/components/ProductionsCollectionDataTable/ProductionsCollectionDataTable";

export default function AdminArticleProductionsView({ article, organization }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("productions", "Productions")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ProductionsCollectionDataTable
					getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
				/>
			</Card.Content>
			<Card.Content extra>
				<Button as={Link} to={`/${organization.type}/${organization.id}/create-production/${article.id}`}>
					{t("createProduction", "Create production")}
				</Button>
			</Card.Content>
		</Card>
	);
}
