import { useState } from "react";
import { useTranslation } from "react-i18next";

import SecondaryActionButton from "../../../ui/components/Buttons/SecondaryActionButton";

import CreateArtifactForm from "./CreateArtifactForm";

export default function CreateArtifactButton({ defaultName, article, publisher, articles, production, ...props }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<SecondaryActionButton onClick={() => setOpen(true)} {...props}>
				{t("createArtifact", "Create artifact")}
			</SecondaryActionButton>

			{open && (
				<CreateArtifactForm
					onClose={() => setOpen(false)}
					article={article}
					articles={articles}
					publisher={publisher}
					production={production}
					defaultName={defaultName}
				/>
			)}
		</>
	);
}
