import { Button } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import api from "../../../../api";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import usePublisherProductionStatistics from "../../../organizations/hooks/usePublisherProductionStatistics";
import ProductionsInstantSearchDataTable from "../../../productions/components/ProductionsInstantSearchDataTable/ProductionsInstantSearchDataTable";

import useAdminProductionsDashboardMenuOptions from "./hooks/useAdminProductionsDashboardMenuOptions";

export default function AdminProductionsDashboardView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [publishers, loading, error] = api.organizations.useAll((query) =>
		query.where("producerIds", "array-contains", organization.id).where("type", "==", organizationTypes.PUBLISHER),
	);

	const [activePublisherId, setActivePublisherId] = useState();

	const menuOptions = useAdminProductionsDashboardMenuOptions({
		publishers,
		organization,
		activePublisherId,
		setActivePublisherId,
	});

	const statistics = usePublisherProductionStatistics({ organization, publishers, activePublisherId });

	return (
		<LoadingContext data={publishers} loading={loading} error={error}>
			<Dashboard menuOptions={menuOptions} statistics={statistics}>
				<ProductionsInstantSearchDataTable
					producer={organization}
					configure={{
						hitsPerPage: 10,
					}}
					visibleColumns={[
						"title",
						"producerName",
						"productionType",
						"status",
						"productionDate",
						"created",
						"menu",
					]}
					footer={<Button onClick={() => navigate("all")}>{t("allProductions", "All productions")}</Button>}
				/>
			</Dashboard>
		</LoadingContext>
	);
}
