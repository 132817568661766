export function getFileDuration(file) {
	if (!file?.type?.includes("audio")) {
		return 0;
	}

	return new Promise((resolve, reject) => {
		const audio = new Audio();

		audio.src = URL.createObjectURL(file);

		audio.onloadedmetadata = () => {
			console.log(audio);
			resolve(audio.duration);
		};

		audio.onerror = reject;
	});
}
