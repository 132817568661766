import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useAuthorsTableColumns from "../../hooks/useAuthorsTableColumns";

export default function AuthorsInstantSearchDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useAuthorsTableColumns({ publisher });

	return (
		<InstantSearchDataTable
			indexName="authors"
			columns={columns}
			configure={{
				filters: publisher && `publisherIds:${publisher.id}`,
			}}
			onClick={({ id }) =>
				navigate(publisher ? `/publisher/${publisher.id}/admin/authors/${id}` : `/admin/authors/${id}`)
			}
			{...props}
		/>
	);
}
