import { Grid, Segment } from "semantic-ui-react";

import React from "react";
import { Outlet } from "react-router-dom";

export default function SidebarLayout({ sidebar, ...props }) {
	return (
		<Segment padded>
			<Grid>
				<Grid.Column width={3}>{sidebar}</Grid.Column>

				<Grid.Column width={13}>
					<Outlet {...props} />
				</Grid.Column>
			</Grid>
		</Segment>
	);
}
