import { Button } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function OldStartPageLink() {
	const { t } = useTranslation();

	return (
		<Button
			size="tiny"
			as={Link}
			to="/home-old"
			basic
			secondary
			content={t("oldStartPage", "To old start page")}
			style={{ marginBottom: 10 }}
		/>
	);
}
