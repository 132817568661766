import { Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";
import ArticleCoverImage from "../../../../articles/components/ArticleCoverImage/ArticleCoverImage";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminTitleDropdownMenuOptions from "../hooks/useAdminTitleDropdownMenuOptions";
import useAdminTitleMenuOptions from "../hooks/useAdminTitleMenuOptions";

export default function AdminTitleHeader({ title }) {
	const { t } = useTranslation();

	const menuOptions = useAdminTitleMenuOptions({ title });

	const { input, loading, error, options: dropdownMenuOptions } = useAdminTitleDropdownMenuOptions({ title });

	return (
		<AdminPageHeader
			error={error}
			loading={loading}
			menuOptions={menuOptions}
			dropdownMenuOptions={dropdownMenuOptions}
		>
			{input}

			<ArticleCoverImage thumb rounded article={title} style={{ width: 80, height: 80 }} loading={loading} />

			<Header style={{ margin: 0 }}>
				<Flex gap={5}>
					{title.name} <div style={{ opacity: 0.4, fontWeight: 400 }}>({t("title", "Title")})</div>
				</Flex>
			</Header>
		</AdminPageHeader>
	);
}
