export default function Flex({
	width = "100%",
	flex,
	justifyContent = "space-between",
	alignItems = "center",
	flexDirection = "row",
	gap = "normal",
	style,
	children,
	...props
}) {
	return (
		<div
			style={{ width, display: "flex", justifyContent, alignItems, gap, flex, flexDirection, ...style }}
			{...props}
		>
			{children}
		</div>
	);
}
