import { useMemo } from "react";

import { filterDistributionChannelsByArticle } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";

import api from "../../../api";

export default function useArticleDistributionChannels({ publisher, article }) {
	const [channels, loading, error] = api.distributionChannels.useAll();

	const filteredChannels = useMemo(
		() => filterDistributionChannelsByArticle({ publisher, article, channels }),
		[article, channels, publisher],
	);

	return [filteredChannels, loading, error];
}
