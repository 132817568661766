import { Icon, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import stopPropagation from "astrid-helpers/src/stopPropagation";

import useLoadingFunction from "../../hooks/useLoadingFunction";

import PrimaryActionButton from "../Buttons/PrimaryActionButton";
import ErrorMessage from "../Messages/ErrorMessage";

export default function ModalPrompt({
	icon,
	error,
	size = "small",
	disabled,
	header,
	children,
	onClose,
	onSave,
	onError,
	confirmText,
	beforeContent,
	afterContent,
	closeOnConfirm = true,
	...props
}) {
	const { t } = useTranslation();

	const [onConfirm, loading, confirmError] = useLoadingFunction(onSave, {
		onSuccess: closeOnConfirm ? onClose : undefined,
		onError,
	});

	return (
		<Modal open size={size} onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation} {...props}>
			{header && (
				<Modal.Header>
					{icon && <Icon name={icon} />} {header}
				</Modal.Header>
			)}
			{beforeContent}

			<Modal.Content>
				{children}

				{(error || confirmError) && <ErrorMessage error={error || confirmError} />}
			</Modal.Content>

			{afterContent}

			<Modal.Actions>
				<PrimaryActionButton color={false} content={t("cancel")} onClick={onClose} />

				<PrimaryActionButton
					content={confirmText || t("save")}
					disabled={disabled}
					loading={loading}
					onClick={onConfirm}
				/>
			</Modal.Actions>
		</Modal>
	);
}
