import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminNarratorHeader from "./components/AdminNarratorHeader";

export default function AdminNarratorView(props) {
	return (
		<AdminPageView {...props}>
			<AdminNarratorHeader />
		</AdminPageView>
	);
}
