import Form from "../../../forms/components/Form/Form";

export default function ArticlesInstantSearchSelect({ publisher, type, noTitle, notProduction, ...props }) {
	const filters = [
		publisher && `publisher.id:${publisher?.id}`,
		notProduction && `NOT production.id:${notProduction.id}`,
		type && `type:${type}`,
		noTitle && "title:false",
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<Form.InstantSearchSelect
			indexName="articles"
			filters={filters}
			getOption={(article) => ({
				key: article.id,
				value: article.id,
				text: article.name,
				data: article,
			})}
			{...props}
		/>
	);
}
