import AppendixesCollection from "../api/agreements/AppendixesCollection";
import ArticleBundlesCollection from "../api/articles/ArticleBundlesCollection";
import ArticlesCollection from "../api/articles/ArticlesCollection";
import ArtifactsCollection from "../api/artifacts/ArtifactsCollection";
import AuthorsCollection from "../api/authors/AuthorsCollection";
import DistributionChannelsCollection from "../api/distribution/DistributionChannelsCollection";
import DistributionsCollection from "../api/distribution/DistributionsCollection";
import ImprintsCollection from "../api/imprints/ImprintsCollection";
import NarratorsCollection from "../api/narrators/NarratorsCollection";
import OrganizationsCollection from "../api/organizations/OrganizationsCollection";
import ProductionsCollection from "../api/productions/ProductionsCollection";
import ScriptsCollection from "../api/scripts/ScriptsCollection";
import SeriesCollection from "../api/series/SeriesCollection";
import StudiosCollection from "../api/studios/StudiosCollection";
import TitlesCollection from "../api/titles/TitlesCollection";
import TranslatorsCollection from "../api/translators/TranslatorsCollection";
import UsersCollection from "../api/users/UsersCollection";

export default class FirestoreAPI {
	constructor(firebase, { plugins, getUser = () => null, debug = false } = {}) {
		this.debug = debug;
		this.firebase = firebase;
		this.firestore = firebase.firestore();

		this.plugins = plugins;
		this.getUser = getUser;

		this.appendixes = new AppendixesCollection(this);
		this.articleBundles = new ArticleBundlesCollection(this);
		this.articles = new ArticlesCollection(this);
		this.artifacts = new ArtifactsCollection(this);
		this.authors = new AuthorsCollection(this);
		this.distributionChannels = new DistributionChannelsCollection(this);
		this.distributions = new DistributionsCollection(this);
		this.imprints = new ImprintsCollection(this);
		this.narrators = new NarratorsCollection(this);
		this.organizations = new OrganizationsCollection(this);
		this.productions = new ProductionsCollection(this);
		this.scripts = new ScriptsCollection(this);
		this.series = new SeriesCollection(this);
		this.studios = new StudiosCollection(this);
		this.titles = new TitlesCollection(this);
		this.translators = new TranslatorsCollection(this);
		this.users = new UsersCollection(this);
	}
}
