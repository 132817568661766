import { useTranslation } from "react-i18next";

import generateCustomXMLFromTemplate from "astrid-distribution/src/actions/processors/metadata/customXml/generateCustomXMLFromTemplate";
import generateExcelFromTemplate from "astrid-distribution/src/actions/processors/metadata/excel/generateExcelFromTemplate";
import generateOnixFromTemplate from "astrid-distribution/src/actions/processors/metadata/onix/generateOnixFromTemplate";

import api from "../../../../../api";
import metadataTemplateTypes from "../../../../superadmin/views/SuperAdminDistributionChannelView/constants/metadataTemplateTypes";

function downloadMetadata({ metadata, fileName, fileType }) {
	const blob = new Blob([metadata], { type: fileType });
	const link = document.createElement("a");
	link.download = fileName;
	link.href = window.URL.createObjectURL(blob);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

async function constructDocument({ article, channel }) {
	const artifact = await api.artifacts.getByRef(article.artifact.ref);
	const publisher = await api.organizations.getByRef(article.publisher.ref);
	return {
		channel,
		publisher,
		article: {
			...article,
			artifact,
		},
	};
}

export default function useArticleDistributionChannelDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	return (channel) => [
		{
			as: "a",
			text: t("view", "View"),
			href: `/admin/distributions/channels/${channel.id}`,
		},
		{
			text: t("remove", "Remove"),
			action: () => {
				return api.articles.removeDistributionChannel({ article, channel });
			},
		},
		{
			text: t("previewOnix", "Preview onix"),
			disabled: !channel.enabledTemplates?.includes?.(metadataTemplateTypes.ONIX),
			action: async () => {
				const document = await constructDocument({ article, channel });
				const metadata = await generateOnixFromTemplate({ document });
				downloadMetadata({
					metadata,
					fileName: `onix-preview-${article.isbn}.xml`,
					fileType: "application/xml",
				});
			},
		},
		{
			text: t("previewExcel", "Preview excel"),
			disabled: !channel.enabledTemplates?.includes?.(metadataTemplateTypes.EXCEL),
			action: async () => {
				const document = await constructDocument({ article, channel });
				const metadata = await generateExcelFromTemplate({
					document,
					domParser: (html) => {
						const parser = new DOMParser();
						return parser.parseFromString(html, "text/html");
					},
					type: "array",
				});

				downloadMetadata({
					metadata,
					fileName: `excel-preview-${article.isbn}.xlsx`,
					fileType: "application/octet-stream",
				});
			},
		},
		{
			text: t("previewCustomTemplate", "Preview custom metadata"),
			disabled: !channel.enabledTemplates?.includes?.(metadataTemplateTypes.CUSTOM_XML),
			action: async () => {
				const document = await constructDocument({ article, channel });
				const metadata = await generateCustomXMLFromTemplate({ document });
				downloadMetadata({
					metadata,
					fileName: `custom-preview-${article.isbn}.xml`,
					fileType: "application/xml",
				});
			},
		},
	];
}
