import React from "react";
import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import linkedArticleTypes from "astrid-firestore/src/api/articles/constants/linkedArticleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import ArticlesInstantSearchSelect from "../../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import useArticleTypeTranslations from "../../../../articles/hooks/useArticleTypeTranslations";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleDataForm({ article, ...props }) {
	const { t } = useTranslation();

	const { publisher } = article;

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			isbn: article.isbn || null,
			linkedArticle: article.linkedArticle || null,
			deliveryDate: article.deliveryDate?.toDate() || null,
			narrators: article.narrators || [],
			pages: article.pages || null,
			duration: article.duration || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		...props,
	});

	const articleTypeTranslations = useArticleTypeTranslations();
	const [narratorOptions] = api.narrators.useOptions({ publisher });

	return (
		<AdminForm header={articleTypeTranslations[article.type]} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="isbn" label={t("isbn", "ISBN")} />

				<ArticlesInstantSearchSelect
					name="linkedArticle"
					label={t("linkedArticle", "Linked article")}
					fluid
					allowEmpty
					clearable
					publisher={publisher}
					type={linkedArticleTypes[article.type]}
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="narrators"
					label={t("readers", "Narrators")}
					search
					allowEmpty
					allowAdditions
					multiple
					options={narratorOptions}
					onAddDocument={(name) => {
						return api.narrators.createData({ name });
					}}
				/>

				<Form.DateInput name="deliveryDate" label={t("deliveryDate", "Delivery date")} disableBeforeToday />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.NumberInput name="pages.start" label={t("startPage", "Start page")} />

				<Form.NumberInput name="pages.end" label={t("endPage", "End page")} />

				{article.type !== articleTypes.EBOOK && (
					<Form.DurationInput name="duration" label={t("duration", "Duration") + " (hhh:mm:ss)"} />
				)}
			</Form.Group>
		</AdminForm>
	);
}
