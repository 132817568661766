import { useTranslation } from "react-i18next";

import formatBytes from "astrid-helpers/src/formatBytes";
import msToTime from "astrid-helpers/src/msToTime";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useArtifactFileDropdownMenuOptions from "./useArtifactFileDropdownMenuOptions";

export default function useArtifactFilesTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			Header: t("Name", "Name"),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
			collapsing: true,
		},
		{
			id: "duration",
			Header: t("duration", "Duration"),
			collapsing: true,
			accessor: ({ duration }) => msToTime(duration * 1000, false),
		},
		{
			id: "size",
			Header: t("size", "Size"),
			collapsing: true,
			accessor: ({ size }) => formatBytes(size),
		},
		dropdownMenuColumn(useArtifactFileDropdownMenuOptions),
	];
}
