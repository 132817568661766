import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../api";
import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function CreateArticleForm({ publisher, production, onClose, onSuccess }) {
	const { t } = useTranslation();

	const articleTypeOptions = useArticleTypeOptions();

	const { form, watch, setValue, onSubmit, error } = useSimpleForm({
		schema: Article.passthrough(),
		values: {
			titleId: null,
			title: false,
			name: "",
			isbn: null,
			type: null,
			publisher,
			production,
		},
		onSubmit: (data) => {
			return api.articles.create(data);
		},
		onSuccess: (data) => {
			onSuccess(data);
			onClose();
		},
	});

	const titleId = watch("titleId");

	const [title] = api.titles.useByIdOnce(titleId);

	useEffect(() => {
		setValue("name", title?.name || "");
		setValue("title", title || false);
	}, [setValue, title]);

	return (
		<ModalPrompt
			size="tiny"
			header={t("createArticle", "Create article")}
			error={error}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Field name="titleId" label={t("titleName", "Title name")}>
					<InstantSearchSelect
						indexName="titles"
						allowEmpty
						clearable
						filters={`publisher.id:${publisher.id}`}
						getOption={(data) => ({
							key: data.id,
							value: data.id,
							text: data.name,
						})}
					/>
				</Form.Field>

				<Form.Input name="isbn" label={t("isbn", "ISBN")} />

				<Form.Input name="name" label={t("articleName", "Article name")} />

				<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />
			</Form>
		</ModalPrompt>
	);
}
