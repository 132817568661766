import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../api";
import useNumberOptions from "../../../ui/hooks/useNumberOptions";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import ThemaModal from "../../../thema/components/ThemaModal";

import AdminForm from "../AdminForm/AdminForm";

export default function AdminMetaDataForm({ header, data, locked, api: documentApi, ...props }) {
	const { t } = useTranslation();

	const { publisher } = data;

	const [openModal, setOpenModal] = useState(false);

	const [authorOptions] = api.authors.useOptions();
	const [translatorOptions] = api.translators.useOptions();

	const [seriesOptions] = api.series.useOptions({ publisher });
	const [imprintOptions] = api.imprints.useOptions({ publisher });

	const numberOptions = useNumberOptions();

	const form = useSimpleForm({
		schema: documentApi.Schema,
		values: {
			additionalLanguages: data.additionalLanguages || null,
			authors: data.authors || [],
			translators: data.translators || [],
			imprint: data.imprint || null,
			language: data.language || null,
			name: data.name || "",
			originalLanguage: data.originalLanguage || null,
			originalName: data.originalName || "",
			ref: data.ref || null,
			serie: data.serie || null,
			serieNumber: data.serieNumber || null,
			synopsis: data.synopsis || "",
			themaCodes: data.themaCodes || null,
		},
		onSubmit: (data) => {
			return documentApi.update(data);
		},
		locked,
		...props,
	});

	const { watch, setValue } = form;

	const [language, themaCodes] = watch(["language", "themaCodes"]);

	return (
		<AdminForm header={header} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />
				<Form.Input name="originalName" label={t("originalName", "Original name")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="authors"
					label={t("authors", "Authors")}
					search
					allowAdditions
					multiple
					options={authorOptions}
					onAddDocument={(name) => {
						return api.authors.createData({ name });
					}}
				/>

				<Form.DocumentSelect
					name="translators"
					label={t("translators", "Translators")}
					search
					allowAdditions
					multiple
					options={translatorOptions}
					onAddDocument={(name) => {
						return api.translators.createData({ name });
					}}
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect name="language" label={t("language", "Language")} clearable />

				<Form.LanguageSelect
					name="originalLanguage"
					label={t("originalLanguage", "Original language")}
					clearable
				/>

				<Form.LanguageSelect
					name="additionalLanguages"
					label={t("otherLanguages", "Other occuring languages")}
					filterOptions={(option) => option.value !== language}
					multiple
					clearable
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="serie"
					label={t("serie", "Series")}
					search
					allowEmpty
					allowAdditions
					options={seriesOptions}
					onAddDocument={(name) => {
						return api.series.createData({ name });
					}}
				/>

				<Form.Select
					name="serieNumber"
					search
					label={t("seriesPart", "Series (part)")}
					allowEmpty
					options={numberOptions}
				/>

				<Form.DocumentSelect
					name="imprint"
					label={t("imprint", "Imprint")}
					search
					allowEmpty
					allowAdditions
					options={imprintOptions}
					onAddDocument={(name) => {
						return api.imprints.createData({ name, publisher });
					}}
				/>
			</Form.Group>

			<Form.TextArea name="synopsis" label={t("synopsis", "Description")} />

			<Form.Select
				multiple
				name="themaCodes"
				label={t("themaCodes", "Thema codes")}
				options={themaCodes.map((code) => ({
					key: code,
					text: code,
					value: code,
				}))}
				onClick={() => !locked && setOpenModal(true)}
			/>

			{openModal && (
				<ThemaModal
					value={themaCodes}
					onClose={({ themaCodes }) => {
						setValue("themaCodes", themaCodes, { shouldDirty: true });
						setOpenModal(false);
					}}
				/>
			)}
		</AdminForm>
	);
}
