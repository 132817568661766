import AdminArticleDataForm from "./components/AdminArticleDataForm";
import AdminArticleMetaDataForm from "./components/AdminArticleMetaDataForm";

export default function AdminArticleSettingsView(props) {
	return (
		<>
			<AdminArticleDataForm {...props} />
			<AdminArticleMetaDataForm {...props} />
		</>
	);
}
