import React from "react";

import useDownloadURL from "astrid-firestore/src/hooks/useDownloadURL";

import PrimaryActionButton from "../../../../ui/components/Buttons/PrimaryActionButton";

export default function DownloadURLButton({ bucket, fullPath, children, loading, error }) {
	const [url, urlLoading, urlError] = useDownloadURL({ bucket, fullPath });

	return (
		<PrimaryActionButton
			loading={urlLoading || loading}
			disabled={urlLoading || urlError || loading || error}
			negative={urlError || error}
			as="a"
			target="_blank"
			href={url}
		>
			{children}
		</PrimaryActionButton>
	);
}
