import { arrayMinMax, language, literal, object } from "../../../types/_types";

import { articleType } from "../../articles/types/Article";

import { DistributionChannelRef } from "./DistributionChannelRef";

export const DistributionPreset = object({
	articleTypes: arrayMinMax(articleType.or(literal("all")), 1),
	languages: arrayMinMax(language().or(literal("all")), 1),
	channels: arrayMinMax(DistributionChannelRef, 1),
});
