import { Button as SemanticButton } from "semantic-ui-react";

const iconStyles = {
	paddingLeft: 16,
	paddingRight: 16,
};

export default function Button({ icon, ...props }) {
	return <SemanticButton basic secondary icon={icon} style={icon && iconStyles} {...props} />;
}
