import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import DistributeArticlesForm from "./DistributeArticlesForm";

export default function DistributeArticlesButton({ publisher, articles, channels, onSuccess, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("distribute", "Distribute")} {...props}>
			{({ setOpen }) => (
				<DistributeArticlesForm
					publisher={publisher}
					articles={articles}
					channels={channels}
					onClose={() => setOpen(false)}
					onSuccess={onSuccess}
				/>
			)}
		</OpenButton>
	);
}
