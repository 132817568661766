import LoadingContext from "../LoadingContext/LoadingContext";
import Table from "../Table/Table";

export default function DataTable({
	data,
	loading,
	error,
	columns,
	searchQuery,
	searchableKeys,
	onClick,
	noDataAvailableMessage,
	visibleColumns,
	allowEmpty,
	nbHits,
	nbPages,
	setPage,
	setSearchQuery,
	...props
}) {
	const filteredData =
		searchableKeys && searchQuery
			? data?.filter((row) =>
					searchableKeys.some((key) => row[key]?.toLowerCase().includes(searchQuery?.toLowerCase())),
			  )
			: data;

	return (
		<LoadingContext
			noDataAvailableMessage={noDataAvailableMessage}
			data={!allowEmpty ? filteredData : undefined}
			loading={loading}
			error={error}
		>
			<Table
				hoverRow
				sortable
				filterable
				pagination
				data={filteredData}
				footer={false}
				columns={columns}
				onClickRow={onClick && ((e, { original }) => onClick(original))}
				hiddenColumns={
					visibleColumns ? columns.map(({ id }) => id).filter((id) => !visibleColumns.includes(id)) : []
				}
				{...props}
			/>
		</LoadingContext>
	);
}
