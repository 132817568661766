import { Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import FlexRow from "../../../../../components/Flex/FlexRow";
import CloudRunStatusLabel from "../../../../distribution/components/CloudRunStatus/CloudRunStatusLabel";
import DistributionStatusLabel from "../../../../distribution/components/DistributionStatus/DistributionStatusLabel";
import useDistributionDropdownMenuOptions from "../../../../distribution/hooks/useDistributionDropdownMenuOptions";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminDistributionMenuOptions from "../hooks/useAdminDistributionMenuOptions";

export default function AdminDistributionHeader({ distribution }) {
	const menuOptions = useAdminDistributionMenuOptions({ distribution });
	const dropdownMenuOptions = useDistributionDropdownMenuOptions(distribution);

	return (
		<AdminPageHeader error={distribution.error} menuOptions={menuOptions} dropdownMenuOptions={dropdownMenuOptions}>
			<Flex>
				<Header style={{ margin: 0 }}>
					<Header.Subheader>{distribution.id}</Header.Subheader>
					{distribution.article.name} {">"} {distribution.channel.name}
				</Header>

				<FlexRow style={{ gap: 10 }}>
					<DistributionStatusLabel distribution={distribution} />

					<CloudRunStatusLabel distribution={distribution} />
				</FlexRow>
			</Flex>
		</AdminPageHeader>
	);
}
