export function filterDistributionChannelsByArticle({ publisher, article, channels = [] }) {
	const presets = publisher?.distribution?.presets?.filter((preset) => {
		return (
			(preset.languages.includes("all") || preset.languages.some((language) => language === article.language)) &&
			(preset.articleTypes.includes("all") || preset.articleTypes.some((type) => type === article.type))
		);
	});

	const presetChannelIds = [...new Set(presets?.flatMap(({ channels }) => channels.map(({ id }) => id)) || [])];

	return channels.filter(
		({ id, active, acceptedLanguages, acceptedArticleTypes }) =>
			active &&
			presetChannelIds.includes(id) &&
			(acceptedArticleTypes?.length === 0 || acceptedArticleTypes?.includes(article.type)) &&
			(acceptedLanguages?.length === 0 || acceptedLanguages?.includes(article.language)),
	);
}

export function filterDistributionChannelsByArticles({ publisher, articles, channels }) {
	return articles.map((article) => ({
		article,
		filteredChannels: filterDistributionChannelsByArticle({ publisher, article, channels }),
	}));
}
