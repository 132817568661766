import FirestoreCollection from "../../firestore/FirestoreCollection";

import { DistributionChannelRef } from "../distribution/types/DistributionChannelRef";

import { Organization } from "./types/Organization";

export default class OrganizationsCollection extends FirestoreCollection {
	static collectionName = "organizations";
	static Schema = Organization;
	static softDeletes = false;

	addDistributionPreset({ publisher, languages, channels, articleTypes }) {
		return this.update(
			{
				ref: publisher.ref,
				"distribution.presets": this.utils.arrayUnion({
					languages,
					channels: channels.map((channel) => DistributionChannelRef.parse(channel)),
					articleTypes,
				}),
			},
			{
				validate: false,
			},
		);
	}

	removeDistributionPreset({ publisher, preset }) {
		return this.update(
			{
				ref: publisher.ref,
				"distribution.presets": this.utils.arrayUnion(preset, false),
			},
			{
				validate: false,
			},
		);
	}

	addDistributionArgs({ publisher, channel, action, args }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.channels.${channel.id}.actions.${action}.args`]: args,
			},
			{
				validate: false,
			},
		);
	}

	removeDistributionArgs({ publisher, channel, action }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.channels.${channel.id}.actions.${action}`]: this.utils.delete(),
			},
			{
				validate: false,
			},
		);
	}
}
