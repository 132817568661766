import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import SecondaryActionButton from "../../../ui/components/Buttons/SecondaryActionButton";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useAgreementTemplatesQuery from "../../../agreements/hooks/useAgreementTemplatesQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminAgreementTemplatesColumns from "./hooks/useAdminAgreementTemplatesColumns";

export default function AdminAgreementTemplatesView({ firstPartyId }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useAgreementTemplatesQuery({ firstPartyId });
	const columns = useAdminAgreementTemplatesColumns();

	return (
		<>
			<AdminHeader text={t("agreementTemplates", "Agreement templates")}>
				<SecondaryActionButton as={Link} to="../templates/create">
					{t("createAgreementTemplate", "Create agreement template")}
				</SecondaryActionButton>
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
