import api from "../../../../api";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import AdminNarratorView from "../../views/AdminNarratorView/AdminNarratorView";

export default function AdminNarrator() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="narrator" api={api.narrators}>
			<AdminNarratorView />
		</FirestoreDocumentContext>
	);
}
