import "moment";
import "moment/locale/sv";

import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import router from "./features/routing/router";
import SentryErrorBoundary from "./features/sentry/components/SentryErrorBoundary";
import "./features/sentry/sentry";
import { StoreProvider } from "./helpers/context";

const root = createRoot(document.getElementById("root"));

root.render(
	<SentryErrorBoundary>
		<StoreProvider>
			<RouterProvider router={router} />
		</StoreProvider>
	</SentryErrorBoundary>,
);
