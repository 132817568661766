const themeColors = {
	GREY: "#767676",
	ORANGE: "#f2711c",
	BLUE: "#2185d0",
	GREEN: "#21ba45",
	RED: "#db2828",
	BLACK: "#000000",
	WHITE: "#ffffff",
};

export default themeColors;
