import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useTranslatorsTableColumns from "../../hooks/useTranslatorsTableColumns";

export default function TranslatorsInstantSearchDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useTranslatorsTableColumns({ publisher });

	return (
		<InstantSearchDataTable
			indexName="translators"
			columns={columns}
			configure={{
				filters: publisher && `publisherIds:${publisher.id}`,
			}}
			onClick={({ id }) =>
				navigate(publisher ? `/publisher/${publisher.id}/admin/translators/${id}` : `/admin/translators/${id}`)
			}
			{...props}
		/>
	);
}
