import FirestoreCollection from "../../firestore/FirestoreCollection";
import { MetaData } from "../../types/MetaData";

import { Article } from "./types/Article";
import { ArticleChannel } from "./types/ArticleChannel";

export default class ArticlesCollection extends FirestoreCollection {
	static collectionName = "articles";
	static Schema = Article;

	// uniqueKeys: ["isbn"],

	preProcess(data) {
		// if (!partial && !data.title) {
		// 	data.title = api.titles.create({ name: data.name, publisher: data.publisher }, { transaction });
		// }

		// if (!partial && data.title) {
		// 	data.titleSync = true;
		// }

		// If we add an author and it doesn't exist, create it
		if (data.authors) {
			data.author = data.authors.map((author) => author.name).join(", ");
			data.authorIds = data.authors.map((author) => author.id);
		}

		// If we add a translator and it doesn't exist, create it
		if (data.translators) {
			data.translator = data.translators.map((translator) => translator.name).join(", ");
			data.translatorIds = data.translators.map((translator) => translator.id);
		}

		// If we add a narrator and it doesn't exist, create it
		if (data.narrators) {
			data.narrator = data.narrators.map((narrator) => narrator.name).join(", ");
			data.narratorIds = data.narrators.map((narrator) => narrator.id);
		}

		// If title is provided, fetch the title data
		if (data.title && data.titleSync) {
			data = { ...data, ...MetaData.parse(data.title) };
		}

		return data;
	}

	async sideEffects({ update, transaction }) {
		const { before, after } = update;

		// Check if metadata has changed sinse last distribution
		if (before.exists && after.exists) {
			const serie = update.get("serie");
			const imprint = update.get("imprint");
			const authors = update.get("authors");
			const narrators = update.get("narrators");
			const translators = update.get("translators");

			const publisherIds = this.utils.arrayUnion(after.publisher.id);

			if (serie.isCreated()) {
				await this.api.series.create({ ...serie.after, publisher: after.publisher }, { transaction });
			}

			if (imprint.isCreated()) {
				await this.api.imprints.create({ ...imprint.after, publisher: after.publisher }, { transaction });
			}

			for (const author of authors.added()) {
				await this.api.authors.createOrUpdate(author, { publisherIds }, { transaction });
			}

			for (const narrator of narrators.added()) {
				await this.api.narrators.createOrUpdate(narrator, { publisherIds }, { transaction });
			}

			for (const translator of translators.added()) {
				await this.api.translators.createOrUpdate(translator, { publisherIds }, { transaction });
			}
		}
	}

	async updateTitleData({ ref, titleSync = false, ...data }) {
		const title = await this.api.titles.getByRef(data.title.ref);

		if (title) {
			return this.api.articles.update({ ref, titleSync, title });
		}
	}

	addDistributionChannel({ article, channel }) {
		return this.api.articles.update({
			ref: article.ref,
			[`channels.${channel.id}`]: ArticleChannel.parse(channel),
		});
	}

	removeDistributionChannel({ article, channel }) {
		return this.api.articles.update({
			ref: article.ref,
			[`channels.${channel.id}`]: this.utils.delete(),
		});
	}
}
