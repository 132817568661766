import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AuthorsInstantSearchDataTable from "../../../authors/components/AuthorsInstantSearchDataTable/AuthorsInstantSearchDataTable";
import CreateAuthorButton from "../../../authors/components/CreateAuthorForm/CreateAuthorButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminAuthorsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("authors", "Authors")}>
				{searchInput}

				{organization && <CreateAuthorButton organization={organization} />}
			</AdminHeader>

			<AuthorsInstantSearchDataTable searchQuery={searchQuery} publisher={organization} />
		</>
	);
}
