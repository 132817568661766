import { Label } from "semantic-ui-react";

export default function ProgressLabel({ percent, color, active, success, children, style, ...props }) {
	return (
		<div style={{ position: "relative", ...style }} {...props}>
			<Label basic={!success} color={color} style={{ margin: 0 }}>
				{children}
			</Label>

			{active && (
				<div
					className={`ui ${color} ${active ? "active" : ""} progress`}
					data-percent={percent}
					style={{
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: 0,
						overflow: "hidden",
						position: "absolute",
						background: "none",
					}}
				>
					<div
						className="bar"
						style={{
							width: `${percent}%`,
							minWidth: 5,
							height: "100%",
							overflow: "hidden",
							borderRadius: 0,
						}}
					>
						<Label
							style={{
								top: 0,
								left: 0,
								margin: 0,
								position: "absolute",
								whiteSpace: "nowrap",
								backgroundColor: "transparent",
								border: "1px solid transparent",
								color: "white",
							}}
						>
							{children}
						</Label>
					</div>
				</div>
			)}
		</div>
	);
}
