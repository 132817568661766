import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsCollectionDataTable({ article, bundle }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();

	const query = api.distributions
		.getQuery({
			article,
			"article.id": bundle && ["in", bundle.articleIds],
		})
		.orderBy("created", "desc")
		.limit(50);

	return (
		<CollectionDataTable
			query={query}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/distributions/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
		/>
	);
}
