import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import api from "../../../../../api";

export default function useAdminNarratorMenuOptions({ narrator }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [articlesCount, articlesCountLoading] = api.articles.useCount((query) =>
		query.where("narratorIds", "array-contains", narrator.id),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(narrator.id),
			text: t("settings", "Settings"),
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			label: articlesCount,
			loading: articlesCountLoading,
		},
	];
}
